import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useAppStateContext } from '../hooks/appState/useAppStateContext';
import PageContainer from './content/PageContainer';
import UserStatus from './UserStatus';
import { Spinner } from '@tbirdcomponents/reactcomponents';
import HtmlContentRenderer from './content/HtmlContentRenderer';
import SiteAdministration from './contentAdmin/SiteAdministration';
function SiteContainer({ onPageNameChange, pageName, articleName, config }) {
    const { Authorization, WebSiteState, FileProcessing, DatabaseProcessing } = useAppStateContext();
    const [pageType, setPageType] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [viewSiteAdmin, setViewSiteAdmin] = useState(false);
    const [editMenu, setEditMenu] = useState(false);
    const [pageModel, setPageModel] = useState(null);
    const [images, setImages] = useState([]);
    const [initialHtml, setInitialHtml] = useState('<div></div>');

    useEffect(() => {
        const fetchInitialHtml = async () => {
            try {
                const response = await fetch('/initialRender.html');
                setInitialHtml(await response.text());
            } catch (error) {
                console.error("Error loading initial HTML:", error);
                setInitialHtml('<div>Could not Load Content</div>');
            }
        };
        fetchInitialHtml();
    }, []);

    useEffect(() => {
        const fetchPageModel = async () => {
            if (pageName) {
                try {
                    const tempPageModel = await DatabaseProcessing.getPageByName(pageName, config?.Site?.websiteId);
                    setPageModel({
                        ...tempPageModel,
                        articles: Array.isArray(tempPageModel.articles) ? tempPageModel.articles : [],
                        keywords: Array.isArray(tempPageModel.keywords) ? tempPageModel.keywords : [],
                        topics: Array.isArray(tempPageModel.topics) ? tempPageModel.topics : [],
                        layouts: Array.isArray(tempPageModel.layouts) ? tempPageModel.layouts : []
                    });
                } catch (error) {
                    if (error.response?.data === 'PageID not found') {
                        console.log('Invalid page name');
                    } else {
                        console.error("Error fetching page model:", error);
                    }
                }
            }
        };
        fetchPageModel();
    }, [DatabaseProcessing, config?.Site?.websiteId, pageName]);

    useEffect(() => {
        const fetchPageInfo = async () => {
            try {
                const loggedInUser = await Authorization.getAuthenticatedUser();
                setLoggedInUser(loggedInUser);
                setPageType(config.Site.siteType);
            } catch (error) {
                console.error("Error fetching page info:", error);
            }
        };
        fetchPageInfo();
    }, [Authorization, config.Site.siteType]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const rootPath = `assets/${config.Site.siteName}/images`;
                const fileList = await FileProcessing.getFileList(rootPath);
                const imageList = fileList.results.map(item => ({
                    url: `${config.Site.appURL}public/${item.key}`,
                    alt: ''  // Consider adding meaningful alt text
                }));
                setImages(imageList);
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        };
        fetchImages();
    }, [FileProcessing, config.Site.appURL, config.Site.siteName]);

    const handleLogin = useCallback((loggedInUser) => {
        setLoggedInUser(loggedInUser);
    }, []);

    const handleLogout = useCallback(() => {
        setEditMenu(false);
    }, []);

    const handleSiteAdmin = useCallback((editSite) => {
        setViewSiteAdmin(editSite);
    }, []);

    const handlePageChange = useCallback(async (page) => {
        try {
            if (page == null) {
                onPageNameChange('Home');
            }else if (page.pageId) {
                const tempPageModel = await DatabaseProcessing.getPageById(page.pageId, config?.Site?.websiteId);
                setPageModel(tempPageModel);
                onPageNameChange(tempPageModel.name);
            }
        } catch (error) {
            if (error.response?.data === 'PageID not found') {
                console.log('Invalid page name');
            } else {
                console.error("Error fetching page model:", error);
            }
        }
    }, [DatabaseProcessing, config?.Site?.websiteId, onPageNameChange]);

    const renderPageContent = useMemo(() => {
        if (viewSiteAdmin) {
            return <SiteAdministration config={config} ></SiteAdministration>;
        }
        else {
            if (pageType === 'BasicSite') {
                if (pageModel == null) {
                    return <HtmlContentRenderer htmlContent={initialHtml} />;
                } else {
                    return (
                        <PageContainer
                            editMenu={editMenu}
                            pageModel={pageModel}
                            imageList={images}
                            config={config}
                            onPageChange={handlePageChange}
                        />
                    );
                }
            } else {
                return <HtmlContentRenderer htmlContent={initialHtml} />;
            }
        }        
    }, [pageType, pageModel, initialHtml, editMenu, images, config, handlePageChange, viewSiteAdmin]);

    return (
        <div className='bodyStyle'>
            <div style={{ float: 'right' }}>
                <UserStatus
                    user={loggedInUser}
                    onLoggedIn={handleLogin}
                    onLoggedOut={handleLogout}
                    onSiteAdmin={handleSiteAdmin}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
                <Spinner showSpinner={WebSiteState.getShowSpinner()} />
            </div>
            <div>{renderPageContent}</div>
        </div>
    );
}

export default SiteContainer;
