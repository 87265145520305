import { ThemeProvider } from '@mui/material/styles';
import { blueTheme } from '@tbirdcomponents/reactcomponents';
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { AppStateContextProvider } from './hooks/appState/AppStateContextProvider';
import { ConfigProvider } from './hooks/configuration/ConfigContext';
import useConfig from './hooks/configuration/useConfig';
import SiteContainer from './sitecontent/SiteContainer';

function App() {
    function MainAppComponent() {
        const { configLoaded, config } = useConfig(); // Load the application Configuration from the config.json file.
        const [pageName, setPageName] = useState("");
        const [articleName, setArticleName] = useState(null);
        const location = useLocation();

        const handlePageNameChange = (input) => {
            setPageName(input)
        };

        //Allows the site to change the configuration based upon the url in the query string when hitting the site.
        useEffect(() => {
            const queryParams = new URLSearchParams(location.search);
            setPageName(queryParams.get('page') ?? 'Home');
            setArticleName(queryParams.get('article') ?? '');
           
        }, [location]); // When the URL changes i.e. the page changes, update the pageName and articleName.

        useEffect(() => {
            console.log("Page name changed:", pageName);
            // Perform any side effects here
        }, [pageName]);

        return (
            <ThemeProvider theme={blueTheme}>
                {/* Load the application configuration and pass it to the SiteContainer component. */}
                <ConfigProvider value={config}>
                    {/* Load the application state and pass it to the SiteContainer component. */}
                    <AppStateContextProvider value={config}>
                        {configLoaded && <SiteContainer onPageNameChange={handlePageNameChange} pageName={pageName} articleName={articleName} config={config} />}
                    </AppStateContextProvider>
                </ConfigProvider>
            </ThemeProvider>
        );
    }

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<MainAppComponent />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
