import React, { useEffect, useState, useCallback, useMemo } from 'react';
function PageCollectionsEditor({ config }) {

    return (
        <div>
            This is the Page Collections Editor

        </div>
    );
}

export default PageCollectionsEditor;