import { API } from 'aws-amplify';
export const DatabaseProcessing = {
    getWebsites: async () => {
        var pageInfo = await API.get('webArchApi', '/website/', {});
        return pageInfo;   
    },
    getPageById: async (pageId, websiteId) => {
        var pageInfo = await API.get('webArchApi', '/page/' + pageId + '/' + websiteId, {});
        return pageInfo;        
    },
    getArticleById: async (pageId) => {
        var pageInfo = await API.get('webArchApi', '/article/' + pageId, {});
        return pageInfo;
    },
    getPageByName: async (pageName,websiteId) => {
        var pageInfo = await API.get('webArchApi', '/page/search/' + pageName +'/'+websiteId, {});
        return pageInfo;
    },
    savePage: async (pageAndArticles) => {
        var pageInformation = { body: { ...pageAndArticles }};
        var pageInfo = await API.post('webArchApi', '/page', pageInformation);
        console.log(pageInfo);
        return pageInfo;
    },
    upsertArticle: async (article) => {
        var articleInfo = { body: { ...article } };
        var newArticleInfo = await API.post('webArchApi', '/article', articleInfo);
        console.log(newArticleInfo);
        return newArticleInfo;
    },
    searchPage: async (searchInfo) => {
        var tempSearchInfo = {
            body: {...searchInfo }
        };
        var pageInfo = [];
        try {
            pageInfo = await API.post('webArchApi', '/page/search', tempSearchInfo);
            console.log(pageInfo);
        }
        catch (e) {
            
        }
        console.log(pageInfo);
        return pageInfo;
    },
    searchCollection: async (searchInfo) => {
        var tempSearchInfo = {
            body: { ...searchInfo }
        };
        var collectionInfo = [];
        try {
            collectionInfo = await API.post('webArchApi', '/collection/search', tempSearchInfo);
            console.log(collectionInfo);
        }
        catch (e) {

        }
        console.log(collectionInfo);
        return collectionInfo;
    },
    searchArticle: async (searchInfo) => {
        var tempSearchInfo = {
            body: { ...searchInfo }
        };
        var pageInfo = await API.post('webArchApi', '/article/search', tempSearchInfo);
        console.log(pageInfo);
        return pageInfo;
    },
    getKeywords: async () => {
        var pageInfo = await API.get('webArchApi', '/keyword', {});
        return pageInfo;
    },
    getTopics: async () => {
        var pageInfo = await API.get('webArchApi', '/topic', {});
        return pageInfo;
    },
    getLayouts: async () => {
        var pageInfo = await API.get('webArchApi', '/layout', {});
        return pageInfo;
    },
    upsertCollections: async (collection) => {
        var collectionInfo = { body: { ...collection } };
        var newcollectionInfo = await API.post('webArchApi', '/collection', collectionInfo);
        return newcollectionInfo;
    },
    getCollectionPage: async (collectionId) => {
        var newcollectionInfo = await API.get('webArchApi', '/collection/' + collectionId, {});
        return newcollectionInfo;
    },
    DeleteCollection: async (collectionId) => {
        var newcollectionInfo = await API.del('webArchApi', '/collection/' + collectionId, {});
        return newcollectionInfo;
    },
    DeletePageFromCollection: async (collectionId, pageId) => {
        var newcollectionInfo = await API.del('webArchApi', '/collection/' + collectionId + '/page/' + pageId, {});
        return newcollectionInfo;
    },
    AssociatePagesWithCollection: async (collectionPageDetail) => {
        var collectionPageDetailInfo = { body: { ...collectionPageDetail } };
        var newcollectionInfo = await API.post('webArchApi', '/collection/' + collectionPageDetail.collection.Id + '/page', collectionPageDetailInfo);
        return newcollectionInfo;
    },
};